.Tours {
    margin-top: 7vh;
    position: relative;
    &--loading {
        min-height: 80vh;
    }
    &__searchbtn {
        position: fixed !important;
        bottom: @normalGap;
        right: @normalGap;
        z-index: 1;
        @media @smallscreen {
            display: none;
        }
    }
    &__header {
        position: relative;
        height: 40vh;
        width: 100%;
        display: grid;
        align-items: center;
        justify-content: center;
        color: @light-text-color;
        text-align: center;
        border-bottom: 4px solid @base-color;
        padding: 0 @normalGap;
        text-shadow: @title-text-shadow;
        &__overlay,
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
        }
        img {
            object-fit: cover;
        }
        &__overlay {
            background: @base-gradient-color;
            background: linear-gradient(
                0deg,
                @base-gradient-color 50%,
                rgba(255, 255, 255, 0.1) 100%
            );
        }
    }
    &__list {
        display: grid;
        grid-gap: @normalGap * 2;
        margin: 5vw;
        &__header {
            color: @light-text-color;
        }
        @media @smallscreen {
            grid-template-columns: repeat(3, 1fr);
            margin: 5vw 5vw 5vw 20vw;
            &__header {
                grid-column: span 3;
            }
        }
	@media @bigscreen {
            grid-template-columns: repeat(4, 1fr);
	    &__header {
                grid-column: span 4;
            }
        }
    }
    &__searchform {
        position: fixed;
        border: 4px solid @base-color;
        left: 2.5vw;
        width: 15vw;
        top: 50%;
        transform: translateY(-50%);
        background: @light-background;
        display: none;
	z-index: 1;
        @media @smallscreen {
            display: block;
        }
        &--mobile-visible {
            display: block;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
            z-index: 2;
        }
        &--search-form-overlay {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: @black;
            z-index: 2;
        }
        .rs-panel-body {
            display: grid;
            grid-gap: @normalGap;
        }
        &__inputgroup {
            display: grid;
            .rs-picker-check {
                display: grid;
            }
        }
        &__closebtn {
            justify-self: end;
        }
    }
    &__background-overlay,
    &__background-overlay-img {
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
    &__background-overlay {
        background: @base-gradient-color;
        background: linear-gradient(180deg, @black 60%, @black 100%);
    }
    &__background-overlay-img {
        object-fit: cover;
    }
}
