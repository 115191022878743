.ListTour {
    padding: 0px;
    border-radius: 2px;
    transition: box-shadow 0.3s;
    padding-bottom: @normalGap;
    border: 4px solid @base-color;
    color: @light-text-color;
    background: @black;
    &:hover {
        box-shadow: @hoverShadow;
    }
    .rs-panel-body {
        padding: 0px;
        display: grid;
        grid-gap: @normalGap;
        position: relative;
	height: 100%;
    }
    &--ghost {
	.rs-panel-body {
            padding: @normalGap;
	}
    }
    &__img {
        width: 100%;
        height: 30vh;
        object-fit: cover;
        @media @smallscreen {
            height: 20vh;
        }
    }
    &__content {
        display: grid;
        grid-gap: @normalGap / 2;
        padding: 0 @normalGap * 2;
        .rs-divider {
            margin: @normalGap 0;
        }
	&__destination {
	    display: grid;
	    grid-template-columns: auto 1fr auto;
	    grid-gap: @normalGap / 2;
	    align-items: center;
	    font-family: @title-font;
	}
        &__title {
            display: grid;
            align-items: start;
	    font-family: @title-font;
        }
        &__tags {
            display: grid;
            grid-gap: @normalGap / 4;
            grid-template-columns: repeat(3, max-content);
            justify-content: start;
            .rs-tag {
                text-align: center;
            }
        }
        &__metadata {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: start;
            align-items: center;
            span {
                font-size: large;
            }
            &__price {
                color: @base-color;
                justify-self: end;
                font-size: x-large !important;
            }
        }
        &__description {
	    font-family: Helvetica;
            div {
                p,
                span {
                    color: @light-text-color!important;
                }
            }
        }
    }
    &__bookbtn {
        float: right;
	align-self: end;
	margin: 0 @normalGap;
	@media @smallscreen {
	    grid-column: span 2;
	}
	@media @bigscreen {
	    grid-column: span 1;
	}
    }
}
