.LandingPage {
    position: relative;
    padding-bottom: @normalGap * 4;
    &--loading {
        height: 100vh;
    }
    &__mainsearchform {
        position: relative;
        margin: 0 5vw;
        margin-top: -15vh;
        background: @light-background;
        z-index: 1;
        border: 4px solid @base-color;
        @media @smallscreen {
            width: unset;
            margin: @centerMargin;
            margin-top: -5vh;
        }
        .rs-panel-body {
            display: grid;
            align-items: end;
            grid-gap: @normalGap;
            @media @smallscreen {
                grid-template-columns: 1fr 1fr max-content;
                justify-content: center;
            }
        }
        &__inputgroup {
            display: grid;
            text-align: left;
        }
    }
    &__mainpromo,
    &__specialoffers,
    &__goodtogo {
        display: grid;
        grid-gap: @normalGap;
        margin-top: 5vh;
        margin-bottom: 5vh;
        color: @light-text-color;
        @media @bigscreen {
            margin: @centerMargin;
        }
        @media @smallscreen {
            margin: 5vh 10vw;
        }
    }
    &__mainpromo {
        &__header {
            font-family: @title-font;
            color: @base-color!important;
        }
        &__header,
        &__desc,
        &__nav {
            text-align: center;
            color: @light-text-color;
        }
        &__desc {
            font-size: large;
            margin: 0 5vw;
        }
        &__items {
            display: grid;
            grid-gap: @normalGap * 2;
            margin: 0 5vw;
            @media @smallscreen {
                margin: unset;
                grid-template-columns: repeat(4, 1fr);
                &--6 {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
            &__item {
                padding: 0px;
                border-radius: 2px;
                transition: box-shadow 0.3s;
                padding-bottom: @normalGap;
                border: 4px solid @base-color;
                color: @light-text-color;
                &:hover {
                    box-shadow: @hoverShadow;
                }
                .rs-panel-body {
                    padding: 0px;
                    display: grid;
                    grid-gap: @normalGap;
                    position: relative;
                }
                &__img {
                    width: 100%;
                    height: 30vh;
                    object-fit: cover;
                    @media @smallscreen {
                        height: 20vh;
                    }
                }
                &__content {
                    display: grid;
                    grid-gap: @normalGap / 2;
                    padding: 0 @normalGap * 2;
                    .rs-divider {
                        margin: @normalGap 0;
                    }
                    &__bookbtn {
                        float: right;
                    }
                    &__tags {
                        display: grid;
                        grid-gap: @normalGap / 4;
                        grid-template-columns: repeat(3, max-content);
                        justify-content: start;
                        .rs-tag {
                            text-align: center;
                        }
                    }
                    &__metadata {
                        display: grid;
                        grid-template-columns: 1fr 4fr;
                        justify-content: start;
                        align-items: center;
                        span {
                            font-size: large;
                        }
                        &__price {
                            color: @base-color;
                            justify-self: end;
                            font-size: x-large !important;
                        }
                    }
                }
            }
        }
    }
    &__specialoffers {
        &__header,
        &__desc {
            text-align: center;
        }
        &__items {
            display: grid;
            grid-gap: @normalGap * 3;
            margin: 0 5vw;
            @media @smallscreen {
                margin: 0;
                grid-template-columns: repeat(3, 1fr);
            }
            &__item {
                position: relative;
                display: grid;
                height: 60vh;
                width: 100%;
                padding: @normalGap * 2;
                align-content: end;
                grid-gap: @normalGap;
                color: white;
                box-shadow: @normalShadow;
                transition: box-shadow 0.3s;
                z-index: 1;
                @media @smallscreen {
                    height: 40vh;
                }
                &:hover {
                    box-shadow: @hoverShadow;
                }
                &__img,
                &__overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    z-index: -1;
                }
                &__overlay {
                    background: rgb(12, 1, 48);
                    background: linear-gradient(
                        0deg,
                        @black 30%,
                        rgba(12, 1, 48, 0.9) 30%,
                        rgba(255, 255, 255, 0.1) 100%
                    );
                }
                &__img {
                    object-fit: cover;
                }
                &__rating {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    align-items: center;
                }
                &__metadata {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: center;
                    .rs-icon {
                        font-size: large;
                    }
                    span {
                        font-size: large;
                    }
                    &__price {
                        color: @base-color;
                        justify-self: end;
                        font-size: x-large !important;
                    }
                }
                &__badge {
                    @size: @normalGap * 6;
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: @size;
                    width: @size;
                    border-radius: 0 0 0 50%;
                    background: @base-color;
                    display: grid;
                    grid-auto-rows: min-content;
                    align-content: center;
                    text-align: center;
                }
            }
        }
    }
    &__goodtogo {
        display: grid;
        grid-gap: @normalGap;
        justify-items: center;
        font-size: large;
        margin-left: 5vw;
        margin-right: 5vw;
        > img {
            height: 40vh;
            border-radius: @normalGap;
        }
        &__content {
            &__header {
                font-size: xx-large;
                font-family: @title-font;
                color: @base-color!important;
            }
        }
        p {
            li {
                list-style-type: square;
            }
        }
        @media @smallscreen {
            grid-template-columns: repeat(2, 1fr);
            margin-left: 10vw;
            margin-right: 10vw;
            &__content {
                &__header {
                    font-size: xxx-large;
                }
            }
        }
    }
    &__background-overlay,
    &__background-overlay-img {
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
    &__background-overlay {
        background: @base-gradient-color;
        background: linear-gradient(
            180deg,
            @transparent-black 60%,
            @transparent-black 100%
        );
    }
    &__background-overlay-img {
        object-fit: cover;
    }
}
