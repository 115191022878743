.Reviews {
    padding: 5vw;
    display: grid;
    grid-gap: @normalGap * 2;
    @media @smallscreen {
        padding: @centerPadding;
    }
    &__list {
        display: grid;
        grid-gap: @normalGap * 2;
    }
    &__review {
        display: grid;
        grid-gap: @normalGap;
        &__header {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: @normalGap / 2;
            align-items: center;
            img {
                height: 15vw;
                width: 15vw;
                object-fit: cover;
                border-radius: 50%;
                @media @smallscreen {
                    height: 5vw;
                    width: 5vw;
                }
            }
            > span {
                display: grid;
                small {
                    color: @light-dark-text-color;
                }
            }
        }
        > p {
            padding-left: 7.5vw;
            @media @smallscreen {
                padding-left: 2.5vw;
            }
        }
    }
}
