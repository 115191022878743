.NavBar {
    position: fixed;
    top: 0;
    left: 0;
    height: 12vh;
    width: 100%;
    z-index: 2;
    display: grid;
    align-items: center;
    background: rgba(0,0,0, 1);
    color: @light-text-color;
    align-items: center;
    &__logo {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
        object-fit: contain;
	padding-left: @normalGap;
	height: 8vh;
    }
    &__items {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        grid-gap: @normalGap;
	padding: 0 @normalGap;
        &--left {
            display: none;
            @media @smallscreen {
                display: grid;
            }
        }
        &--right {
            justify-self: end;
        }
        &__item {
            font-size: large;
	    cursor: pointer;
            .rs-icon {
                font-size: x-large;
                color: @base-color;
            }
            &--right {
                justify-self: end;
            }
            &--myprofile {
                display: none;
                @media @smallscreen {
                    display: block;
                }
            }
	    &--mobile-hidden {
		display: none;
		@media @smallscreen {
		    display: block;
		}
	    }
            &--burgermenu {
                @media @smallscreen {
                    display: none;
                }
                .rs-icon {
                    color: white;
                }
            }
        }
    }
    &__menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: @black;
        @media @smallscreen {
            display: none;
        }
    }
    &__menu {
        width: 80%;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: @light-background;
        border: 4px solid @base-color;
        @media @smallscreen {
            display: none;
        }
        z-index: 1;
        .rs-panel-body {
            display: grid;
            grid-gap: @normalGap * 2;
        }
        &__item {
            color: @base-color;
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            text-align: center;
        }
        &__closebtn {
            margin-top: @normalGap * 2;
        }
    }
}
