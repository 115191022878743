.TourView {
    position: relative;
    background: black;
    &--loading {
        height: 100vh;
    }
    .carouselimgmixin {
        border-radius: 5px;
        margin-right: @normalGap;
        height: 100%;
        width: 80%;
        object-fit: cover;
        cursor: pointer;
        @media @smallscreen {
            width: 30%;
        }
    }
    &__header {
        display: grid;
        align-content: end;
        grid-gap: @normalGap;
        width: 100%;
        height: calc(100vh - @navBarHeight);
        position: relative;
        color: @light-text-color;
        padding: 5vw;
        border-bottom: 4px solid @base-color;
	z-index: 1;
        @media @smallscreen {
            height: calc(90vh - @navBarHeight);
            padding: @normalGap * 4;
        }
        > img,
        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
        > img {
            object-fit: cover;
        }
        &__overlay {
            background: @base-color;
            background: linear-gradient(
                0deg,
                @base-gradient-color 30%,
                rgba(255, 255, 255, 0) 100%
            );
        }
        &__bookbtn {
            position: fixed !important;
            bottom: 2vh;
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            width: 80%;
            @media @smallscreen {
                left: unset;
                right: 5vw;
                bottom: 5vh;
                transform: unset;
                width: unset;
            }
            @media @bigscreen {
                display: none;
            }
        }
        &__title {
	    width: 100%;
            font-family: @title-font;
            text-shadow: @title-text-shadow;
	    font-size: 36px;
	    @media @smallscreen {
		width: 80%;
		font-size: 46px;
	    }
        }
        &__destination {
            font-family: @title-font;
            text-shadow: @title-text-shadow;
        }
        &__carousel {
            display: inline-block;
            white-space: nowrap;
            height: 30vh;
            overflow-x: auto;
            @media @smallscreen {
                margin-top: @normalGap * 2;
                width: 80%;
            }
            img {
                .carouselimgmixin();
            }
        }
    }
    &__description,
    &__itinerary,
    &__inclexcl,
    &__additionalinfo {
        margin: 5vw;
        margin-top: 10vw;
        color: white;
        @media @smallscreen {
            margin: @centerPadding;
            margin-top: 2vw;
        }
    }
    &__description {
        display: grid;
        grid-gap: @normalGap;
        color: @light-text-color;
        &__map {
            height: 50vh;
            border-radius: 5px;
        }
    }
    &__itinerary {
        color: @light-text-color;
        .rs-panel-group {
            border: 4px solid @base-color;
            background: @black;
        }
        &__header {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            &__duration {
                justify-self: end;
            }
        }
        &__item {
            .rs-panel-heading {
                &:before {
                    color: @light-text-color;
                }
            }
            .rs-panel-body {
                display: grid;
                grid-gap: @normalGap;
            }
            &__header {
                display: inline-grid;
                grid-template-columns: auto 1fr;
                grid-gap: @normalGap;
                align-items: center;
                color: @light-text-color;
                img {
                    height: 15vw;
                    width: 15vw;
                    border-radius: 50%;
                    object-fit: cover;
                    @media @smallscreen {
                        height: 8vh;
                        width: 8vh;
                    }
                }
                span {
                    display: inline-grid;
                    small {
                        color: @light-text-color;
                    }
                }
            }
            &__carousel {
                display: inline-block;
                overflow-x: auto;
                white-space: nowrap;
                height: 30vh;
                img {
                    .carouselimgmixin();
                }
            }
        }
    }
    &__inclexcl {
        display: grid;
        grid-gap: @normalGap;
        &__inclusions,
        &__exclusions {
            .rs-list {
                box-shadow: unset;
            }
            > h4 {
                color: @light-text-color;
            }
            &__item {
                background: rgba(0, 0, 0, 0) !important;
                color: @light-text-color;
                border-bottom: 4px solid @base-color;
                box-shadow: unset;
                .rs-list-item-content {
                    display: grid;
                    grid-gap: @normalGap / 2;
                }
                .rs-avatar {
                    background: green;
                }
                h5 {
                    display: grid;
                    grid-template-columns: max-content 1fr;
                    grid-gap: @normalGap / 2;
                    align-items: center;
                }
                &__description {
                    padding: @normalGap / 2;
                }
            }
        }
        &__exclusions {
            &__item {
                .rs-avatar {
                    background: red;
                }
            }
        }
    }
    &__additionalinfo {
        display: grid;
        border: 4px solid @base-color;
        background: @black;
        .rs-panel-body {
            display: grid;
            grid-gap: @normalGap;
        }
        &__cxl {
            font-weight: bold;
            > li {
                list-style-type: square;
            }
            &__free {
                color: green;
            }
            &__chargeable {
                color: red;
            }
        }
    }
    &__priceinfo,
    &__bookingprocess {
        position: fixed;
        transform: translateY(-50%);
        background: @mainBackground;
        top: 50%;
        left: 10%;
        width: 80%;
        border: 4px solid @base-color;
        @media @smallscreen {
            width: 30%;
            left: 35%;
        }
        &__actions {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: @normalGap;
            align-self: end;
            margin-top: @normalGap * 2;
            &--1 {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
    &__priceinfo {
        display: none;
	z-index: 2;
        @media @bigscreen {
            display: block;
            top: 60%;
            right: calc(3vw - @normalGap * 2);
            left: unset;
            width: 17vw;
        }
        &--mobile-visible {
            display: block;
        }
        .rs-panel-body {
            display: grid;
            grid-gap: @normalGap / 2;
            min-height: 50vh;
            align-content: space-between;
        }
        &__price {
            color: @base-color;
        }
        &__inputgroup {
            display: grid;
            grid-gap: @normalGap / 2;
            &__pax {
                display: grid;
                grid-template-columns: repeat(3, max-content);
                grid-gap: @normalGap;
                justify-items: center;
                align-items: center;
                strong {
                    font-size: x-large;
                }
            }
        }
        &__overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: @black;
            z-index: 2;
        }
        &__closebtn {
            position: absolute !important;
            right: @normalGap;
            top: @normalGap;
        }
    }
    &__priceinfo,
    &__bookingprocess {
        &__price {
            color: @base-color;
        }
        &__timeline {
            margin-left: 20px;
            .rs-timeline-item-custom-dot {
                .rs-icon {
                    position: absolute;
                    background: #fff;
                    top: 0;
                    left: -2px;
                    border: 2px solid #ddd;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    font-size: 18px;
                    padding-top: 9px;
                    color: #999;
                    margin-left: -13px;
                }
            }
            .rs-timeline-item-content {
                margin-left: 24px;
            }
        }
    }
    &__bookingprocess {
        height: 90%;
        overflow: hidden;
        .rs-panel-body {
            display: grid;
            height: 100%;
            overflow: hidden;
            grid-template-rows: auto auto 1fr auto;
        }
        &__content {
            overflow: auto;
        }
        &--traveller-info {
            @media @smallscreen {
                width: 60%;
                left: 20%;
                &--1 {
                    width: 30%;
                    left: 35%;
                }
            }
            .rs-panel-body {
                display: grid;
                grid-template-rows: auto auto 1fr auto;
                height: 100%;
                overflow: hidden;
            }
        }
        &--payment {
            width: 80%;
            left: 10%;
            height: 60vh;
            @media @smallscreen {
                width: 20%;
                left: 40%;
                height: 40vh;
            }
        }
        &__overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: @black;
            z-index: 2;
        }
        &__travellers {
            display: grid;
            grid-gap: @normalGap;
            overflow: auto;
            height: 100%;
            grid-auto-flow: row;
            grid-auto-rows: max-content;
            @media @smallscreen {
                grid-auto-flow: unset;
                grid-template-columns: repeat(2, 1fr);
                &--1 {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
        &__remarks {
            textarea {
                min-width: unset;
                width: 100%;
            }
        }
        &__payment-success,
        &__payment-failed,
        &__booking-failed,
        &__booking-success {
            text-align: center;
            display: grid;
            align-content: center;
            height: 100%;
            grid-gap: @normalGap * 2;
            grid-auto-rows: max-content;
            .rs-icon {
                color: orange;
            }
        }
        &__booking-failed {
            .rs-icon {
                color: red;
            }
        }
        &__booking-success {
            &__reference {
                color: @base-color;
                .rs-icon {
                    color: @base-color!important;
                }
            }
            .rs-icon {
                color: green;
            }
        }
    }
    &__background-overlay,
    &__background-overlay-img {
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
    &__background-overlay {
        background: @base-gradient-color;
        background: linear-gradient(
            180deg,
            @transparent-black 60%,
            @transparent-black 100%
        );
    }
    &__background-overlay-img {
        object-fit: cover;
    }
    &__img-carousel {
        z-index: 9999;
        position: fixed;
        top: 0;
        left: 0;
        background: black;
        height: 100vh;
        width: 100vw;
        &__carousel {
            @wsize: 80vw;
            @hsize: 80vh;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: @wsize;
            height: @hsize;
            .rs-panel {
                border: 4px solid @base-color;
                .rs-panel-body {
                    padding: @normalGap;
                    display: grid;
                    align-items: center;
                    justify-items: center;
                }
            }
            &__img {
                width: calc(@wsize - (@normalGap * 4));
                height: calc(@hsize - (@normalGap * 4));
                object-fit: cover;
            }
            &__idx {
                @size: 3rem;
                position: absolute;
                display: block;
                background: @base-color;
                height: @size;
                width: @size;
                border-radius: 50%;
                color: white;
                display: grid;
                align-items: center;
                justify-content: center;
                grid-auto-flow: column;
                grid-auto-columns: max-content;
                top: calc(-@size / 2);
                left: 50%;
                transform: translateX(-50%);
            }
            &__prev,
            &__next {
                position: absolute !important;
                top: 50%;
                transform: translate(-50%, -50%);

                z-index: 1;
            }
            &__next {
                right: 0;
                transform: translate(50%, -50%);
            }
            &__close-btn {
                position: absolute !important;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
