@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');

@import "../../node_modules/rsuite/lib/styles/index.less";

@import "./variables.less";
@import "./pages/LandingPage.less";
@import "./pages/TourView.less";
@import "./pages/Tours.less";

@import "./components/NavBar.less";
@import "./components/Reviews.less";
@import "./components/ListTour.less";
@import "./components/PaymentForm.less";

.App {
    height: 100vh;
    display: grid;
    font-family: @text-font;
}

.custom-link {
    color: unset;
    text-decoration: none;
    cursor: pointer;
    &:focus,
    &:active,
    &:hover {
        color: unset!important;
        text-decoration: none!important;
    }
}

.rs-btn-primary {
    color: @light-text-color!important;
}

.full-screen-loader {
    .rs-loader-spin {
        &:after {
            border-color: @base-color transparent transparent;
        }
    }
    .rs-loader-content {
        color: @base-color;
    }
}

.rs-picker-menu {
    z-index: 9999;
}
