.PaymentForm {
    display: grid;
    grid-auto-rows: max-content;
    align-content: space-between;
    height: 100%;
    &__header,
    &__price {
        color: @base-color;
        text-align: center;
    }
    &__header {
	color: unset;
    }
    &__form {
        display: grid;
        grid-gap: @normalGap;
        &__card-element {
            border: 2px solid @base-color;
            padding: @normalGap;
            border-radius: 5px;
        }
        &__paybtn {
            margin-top: @normalGap;
        }
        &__actions {
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-gap: @normalGap / 2;
            align-items: end;
        }
        .rs-loader-wrapper {
            z-index: 1;
        }
    }
}
